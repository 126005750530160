<template>
  <div class="Home">
    <!-- <a href="#form">
      <img loading="lazy" src="img/simuladores.svg" class="simuladores" alt=""
    /></a> -->
    <div class="container-fluid menu_ajuste">
      <div class="row">
        <!-- CONTENIDO TITULAR -->
        <div class="col-lg-10 mt-4">
          <div class="row text-left d-flex justify-content-center">
            <div class="col-lg-2"></div>
            <div class="col-lg-6">
              <h1 class="tt_sub_s">Subsidio</h1>
              <h2 class="st_sub_s">
                Cada vez estás más cerca de tu casa propia…
              </h2>
              <p class="tx-sub_S">
                En hipotecaria Evoluciona, empresa 100% chilena queremos
                ayudarte a cumplir este sueño. con un conjunto de productos, una
                atención personalizada y la mejor asesoría.
              </p>
            </div>

            <div class="col-lg-2">
              <a href="" class="btn-subcliente"></a>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
        <!-- CONTENIDO TITULAR -->

        <!-- CONTENIDO CENTRAL -->
        <div class="col-lg-10">
          <!-- CARD GRID -->
          <div class="row d-flex justify-content-center">
            <!-- CARD -->
            <div class="col-lg-3">
              <div class="card-sub-2 text-center">
                <h4 class="tt_b">
                  Elige mes a mes <br />
                  cuanto pagar de dividendo
                </h4>
                <img
                  loading="lazy"
                  src="img/icono_dividendo_flexible.png"
                  class="w-100"
                  alt=""
                />
              </div>
            </div>
            <!-- CARD -->
            <!-- CARD -->
            <div class="col-lg-3">
              <div class="card-sub-2 text-center">
                <h4 class="tt_b">
                  Seguro <br />
                  de desgravamen + itp 2/3*<br />
                </h4>
                <img
                  loading="lazy"
                  src="img/icono_seguro_desgravamen.png"
                  class="w-100"
                  alt=""
                />
              </div>
            </div>
            <!-- CARD -->
            <!-- CARD -->
            <div class="col-lg-3">
              <div class="card-sub-2 text-center">
                <h4 class="tt_b">
                  ¡Seguro de cesantía <br />
                  sin costo para ti!
                </h4>
                <img
                  loading="lazy"
                  src="img/manos_01.png"
                  class="w-100"
                  alt=""
                />
              </div>
            </div>
            <!-- CARD -->

            <div class="col-lg-9 text-left p-4 mt-3">
              <div class="col-8">
                <p class="tx-sub_S">
                  *Itp 2/3: Cubre la pérdida irreversible y definitiva a
                  consecuencia de una enfermedad o accidente o debilitamiento de
                  las fuerzas físicas o intelectuales del asegurado, que
                  implique la pérdida de, a lo menos, dos tercios (2/3) de la
                  capacidad de trabajo.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- CARD GRID -->

        <div class="col-lg-10 mt-4">
          <!-- CARD PLAZOS -->
          <div class="row d-flex justify-content-center pt-4 mt-4">
            <div class="col-lg-12">
              <p class="tx-sub_S">
                Sin productos asociados como cuenta corriente, <br />
                tarjetas de crédito o comisiones
              </p>
            </div>

            <div class="col-lg-3">
              <img
                loading="lazy"
                src="img/icono_simulacion_online.png"
                alt=""
              />
            </div>
            <div class="col-lg-3 p-4">
              <h1 class="ttx-con">No somos un Banco.</h1>
              <h2 class="ttx-con2">
                <span class="plus_comin">+</span> rápidos
                <span class="plus_comin">+</span> simples
              </h2>
            </div>
            <div class="col-lg-3">
              <img loading="lazy" src="img/icono_credito_aprobado.png" alt="" />
            </div>
          </div>
          <!-- CARD PLAZOS -->
          <!-- CONTENIDO CENTRAL -->
        </div>
      </div>
      <!-- Cierre Contenedor  -->

      <!-- LINEA -->
      <div class="col-lg-10 p-4">
        <img loading="lazy" src="img/line-e.png" width="50%" alt="" />
      </div>
      <!-- LINEA -->

      <div class="col-lg-10 mt-4">
        <!-- CONTENIDO TITULAR -->
        <div class="m-4">
          <div class="row text-left d-flex justify-content-center">
            <div class="col-lg-12">
              <h1 class="st_sub_s text-center">
                Algunos beneficios si pagas tu dividendo puntualmente
              </h1>
              <h2 class="tx-sub_S text-center">
                Serviu premia el buen complimiento con hasta un 20% de descuento
                <br />
                en tu cuota (se descuentan automáticamente en caja)
              </h2>
            </div>
          </div>
        </div>
        <!-- CONTENIDO TITULAR -->

        <!-- CONTENIDO CENTRAL -->

        <!-- CARD GRID -->
        <div class="row d-flex justify-content-center">
          <!-- CARD -->
          <div class="col-lg-3">
            <div class="card-sub-2 text-center">
              <h4 class="tt_b_2 h-25">
                Crédito hasta <br />
                500 UF
              </h4>
              <span class="igual">=</span>
              <h4 class="tt_b_1">20% Pago mensual</h4>
              <img loading="lazy" src="img/check.png" alt="" />
            </div>
          </div>
          <!-- CARD -->
          <!-- CARD -->
          <div class="col-lg-3">
            <div class="card-sub-2 text-center">
              <h4 class="tt_b_2 h-25">Crédito desde 500 UF hasta 900 UF</h4>
              <span class="igual">=</span>
              <h4 class="tt_b_1">15% Pago mensual</h4>
              <img loading="lazy" src="img/check.png" alt="" />
            </div>
          </div>
          <!-- CARD -->
          <!-- CARD -->
          <div class="col-lg-3">
            <div class="card-sub-2 text-center">
              <h4 class="tt_b_2 h-25">Crédito desde 900 UF hasta 1200 UF</h4>
              <span class="igual">=</span>
              <h4 class="tt_b_1">10% Pago mensual</h4>
              <img loading="lazy" src="img/check.png" alt="" />
            </div>
          </div>
          <!-- CARD -->
        </div>
        <!-- CARD GRID -->

        <!-- CONTENIDO TITULAR -->
        <div class="m-4 mt-5">
          <div class="row text-left d-flex justify-content-center">
            <div class="col-lg-12">
              <h1 class="st_sub_s text-center">
                Además, cuentas con seguro de cesantía.
              </h1>
              <h2 class="tx-sub_S text-center">
                No pagas prima mensual por dicho seguro, sin embargo si llegas a
                quedar <br />
                cesante, este cubre hasta 6 meses tu dividendo, por todo el
                período del crédito.
              </h2>
            </div>
          </div>
        </div>
        <!-- CONTENIDO TITULAR -->

        <!-- CARD PLAZOS -->
        <div class="row d-flex justify-content-center p-4 mb-4">
          <div id="form"></div>
          <div class="col-lg-8">
            <h1 class="st_sub_s text-left">
              Aspectos relevantes a tener en cuenta:
            </h1>
            <ul class="lists text-left">
              <li class="tx-sub_S">
                <span class="bullets">●</span>
                Debes ser mayor de 18 años.
              </li>
              <li class="tx-sub_S">
                <span class="bullets">●</span>
                Debes acreditar renta necesaria para el monto de crédito
                solicitado.
              </li>
              <li class="tx-sub_S">
                <span class="bullets">●</span>
                Puedes complementar renta con tu pareja o cónyuge o con un
                tercero.
              </li>
              <li class="tx-sub_S">
                <span class="bullets">●</span>
                Antigüedad laboral debe ser mayor a 3 meses en el caso de ser
                trabajador dependiente.
              </li>

              <li class="tx-sub_S">
                <span class="bullets">●</span>
                Antigüedad laboral debe ser mayor a 12 meses en el caso de ser
                trabajador independiente.
              </li>
              <li class="tx-sub_S">
                <span class="bullets">●</span>
                Financiamiento desde las UF 500
              </li>
            </ul>
          </div>
          <!-- CONTENIDO CENTRAL -->
          <!-- FORMULARIO -->
          <!-- <FormSubsidio /> -->
          <!-- FORMULARIO -->
        </div>
        <!-- CARD PLAZOS -->
      </div>
      <!-- Cierre Contenedor  -->
    </div>
  </div>
</template>

<script>
import { creditTypes } from '../../data/creditTypes';
import CommonService from '../../service/CommonService';
import store from '../../store/index';

export default {
  name: 'Home',
  created() {
    CommonService.scrollToTop();
    store.commit('changeCreditType', creditTypes.SUBSIDY);
  },
};
</script>
