<template>
  <!-- FORMULARIO SUBSIDIO  -->

  <div class="row d-flex justify-content-center">
    <!-- DESPLIEGUE RESULTADO -->
    <!-- TITULAR -->
    <div v-show="status === 200" class="p-1">
      <h1 class="tt_icons">
        <img alt="" src="img/icons.png" />
        <span class="tusim">Resultado</span> de la simulación
      </h1>
    </div>
    <!-- CUERPO -->
    <div v-show="status === 200" class="form-sub col-lg-9">
      <!-- HEADER RESPUESTA -->
      <div class="header_resp row">
        <div class="col-lg-4 linea-blanca">
          <p class="strong-tx">
            Monto del Crédito:
            <span class="resul-tx-sim">{{ getPorc(credito) }}</span>
          </p>
        </div>
        <div class="col-lg-4 linea-blanca">
          <p class="strong-tx">
            Tasa: <span class="resul-tx-sim"> {{ rates }}%</span>
          </p>
        </div>
        <div class="col-lg-4">
          <p class="strong-tx">
            UF del Dia:
            <span class="resul-tx-sim">{{ formatPrice(ufhoy) }}</span>
          </p>
        </div>
      </div>
      <!-- HEADER RESPUESTA -->

      <!-- CUERPO RESPUESTA -->
      <div class="row">
        <!-- CUERPO TABLA -->
        <div class="col-lg-12">
          <!-- TABLA COTIZACION -->
          <div class="table-responsive mt-4">
            <table class="table table-borderless">
              <thead class="header-table-simulador">
                <tr>
                  <th>
                    <p class="head-table-sim" style="margin: 0px; padding: 0px">
                      Plazo (años)
                    </p>
                  </th>
                  <th>
                    <p class="head-table-sim" style="margin: 0px; padding: 0px">
                      Dividendo ($)
                    </p>
                  </th>
                  <th>
                    <p class="head-table-sim" style="margin: 0px; padding: 0px">
                      Dividendo (UF)
                    </p>
                  </th>
                  <th>
                    <p class="head-table-sim" style="margin: 0px; padding: 0px">
                      Renta Requerida
                    </p>
                  </th>
                  <th>
                    <p class="head-table-sim" style="margin: 0px; padding: 0px">
                      CAE
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody style="color: #17418a">
                <tr v-for="simulacion in simulaciones" :key="simulacion.id">
                  <th>
                    <p class="head-table-sim">
                      {{ formatPrice(simulacion.longTermMax / 12) }}
                    </p>
                  </th>
                  <td>
                    <p class="head-table-sim">
                      <b>$ {{ formatPrice(simulacion.total * ufhoy) }}</b>
                    </p>
                  </td>
                  <td>
                    <p class="head-table-sim">
                      {{ formatporcentaje(simulacion.total) }}
                    </p>
                  </td>
                  <td>
                    <p class="head-table-sim">
                      $
                      {{
                        formatPrice(
                          ((simulacion.total * 75) / 25 + simulacion.total) *
                            ufhoy
                        )
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="head-table-sim">
                      {{ formatporcentaje(simulacion.cae) }}%
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- TABLA COTIZACION -->
          </div>
        </div>
        <!-- CUERPO TABLA -->

        <div class="col-lg-12 m-0 p-0">
          <div class="row justify-content-center gap">
            <div class="col-lg-12">
              <p class="tx-sim-corr text-center">Solicitar Ejecutivo</p>
            </div>

            <div class="col-lg-12">
              <button
                class="btn btn-simulador"
                type="button"
                @click="contact()"
              >
                info@hipotecariaevoluciona.cl
              </button>
            </div>

            <div class="col-lg-12 mt-2">
              <button
                class="btn btn-simulador"
                data-bs-target="#gastos"
                data-bs-toggle="modal"
                type="button"
              >
                Gastos Operacionales
              </button>
            </div>

            <div class="col-lg-12 mt-2">
              <button
                class="btn btn-simulador"
                data-bs-target="#dividendos"
                data-bs-toggle="modal"
                type="button"
              >
                Entiende tu dividendo
              </button>
            </div>
          </div>

          <!-- MODAL DE GASTOS -->
          <div
            id="gastos"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            class="modal fade modal-bg-simulador"
            tabindex="-1"
          >
            <div class="modal-dialog modal-lg">
              <h5 class="tt-gasto">Gasto Operacionales</h5>
              <div class="modal-body-simulador">
                <div class="modal-body-simulador">
                  <table class="table table-borderless">
                    <thead class="thead-gasto">
                      <tr>
                        <th scope="col"><p class="p-sim">#</p></th>
                        <th scope="col"><p class="p-sim">Descripción</p></th>
                        <th scope="col"><p class="p-sim">Monto(UF)</p></th>
                        <th scope="col"><p class="p-sim">Monto($)</p></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="gasto in gastosoperacionales" :key="gasto.id">
                        <td>
                          <p class="p-sim">
                            {{ gasto.operationalExpensesEnum.id }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            {{ gasto.operationalExpensesEnum.description }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">{{ gasto.amount }}</p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(gasto.amount * ufhoy) }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                class="btn btn-cerrar"
                data-bs-dismiss="modal"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
          <!-- GASTOS OPERACIONALES -->

          <br /><br />

          <!-- MODAL DIVIDENDO -->
          <div
            id="dividendos"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            class="modal fade modal-bg-simulador"
            tabindex="-1"
          >
            <div class="modal-dialog modal-lg">
              <h5 class="tt-gasto">Entiende tu dividendo</h5>
              <div class="modal-body-simulador">
                <div class="modal-body-simulador">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col"><p class="p-sim">Plazo</p></th>
                        <th scope="col"><p class="p-sim">Dividendo Neto</p></th>
                        <th scope="col">
                          <p class="p-sim">Seguro Desgravamen</p>
                        </th>
                        <th scope="col">
                          <p class="p-sim">Seguro Incendio y Sismo</p>
                        </th>
                        <th scope="col">
                          <p class="p-sim">Dividendo Total</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="simulacion in simulaciones"
                        :key="simulacion.id"
                      >
                        <th>
                          <p class="p-sim">
                            {{ formatPrice(simulacion.longTermMax / 12) }}
                          </p>
                        </th>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(simulacion.dividend * ufhoy) }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $
                            {{ formatPrice(simulacion.insuranceDesg * ufhoy) }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(simulacion.insuranceInc * ufhoy) }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(simulacion.total * ufhoy) }}
                          </p>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                class="btn btn-cerrar"
                data-bs-dismiss="modal"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
          <!-- MODAL DIVIDENDO -->
          <!-- ENTIENDE TU DIVIDENDO -->
        </div>

        <div class="col-lg-12">
          <!-- MODAL DE GASTOS -->
          <div
            id="gastos"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            class="modal fade modal-bg-simulador"
            tabindex="-1"
          >
            <div class="modal-dialog modal-lg">
              <h5 class="tt-gasto">Gasto Operacionales</h5>
              <div class="modal-body-simulador">
                <div class="modal-body-simulador">
                  <table class="table table-borderless">
                    <thead class="thead-gasto">
                      <tr>
                        <th scope="col"><p class="p-sim">#</p></th>
                        <th scope="col"><p class="p-sim">Descripción</p></th>
                        <th scope="col"><p class="p-sim">Monto(UF)</p></th>
                        <th scope="col"><p class="p-sim">Monto($)</p></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="gasto in gastosoperacionales" :key="gasto.id">
                        <td>
                          <p class="p-sim">
                            {{ gasto.operationalExpensesEnum.id }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            {{ gasto.operationalExpensesEnum.description }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">{{ gasto.amount }}</p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(gasto.amount * ufhoy) }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                class="btn btn-cerrar"
                data-bs-dismiss="modal"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
          <!-- GASTOS OPERACIONALES -->

          <br /><br />

          <!-- MODAL DIVIDENDO -->
          <div
            id="dividendos"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            class="modal fade modal-bg-simulador"
            tabindex="-1"
          >
            <div class="modal-dialog modal-lg">
              <h5 class="tt-gasto">Entiende tu dividendo</h5>
              <div class="modal-body-simulador">
                <div class="modal-body-simulador">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col"><p class="p-sim">Plazo</p></th>
                        <th scope="col"><p class="p-sim">Dividendo Neto</p></th>
                        <th scope="col">
                          <p class="p-sim">Seguro Desgravamen</p>
                        </th>
                        <th scope="col">
                          <p class="p-sim">Seguro Incendio y Sismo</p>
                        </th>
                        <th scope="col">
                          <p class="p-sim">Dividendo Total</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="simulacion in simulaciones"
                        :key="simulacion.id"
                      >
                        <th>
                          <p class="p-sim">
                            {{ formatPrice(simulacion.longTermMax / 12) }}
                          </p>
                        </th>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(simulacion.dividend * ufhoy) }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $
                            {{ formatPrice(simulacion.insuranceDesg * ufhoy) }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(simulacion.insuranceInc * ufhoy) }}
                          </p>
                        </td>
                        <td>
                          <p class="p-sim">
                            $ {{ formatPrice(simulacion.total * ufhoy) }}
                          </p>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                class="btn btn-cerrar"
                data-bs-dismiss="modal"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
          <!-- MODAL DIVIDENDO -->
          <!-- ENTIENDE TU DIVIDENDO -->
        </div>
        <!-- VOLVER Y DESCARGAR  -->
        <div class="col-lg-12 p-0 m-0">
          <div class="row m-0 p-0">
            <div class="col-6">
              <button class="btn btn-volver-simular" @click="reset()">
                Volver a Simular
              </button>
            </div>

            <div class="col-6">
              <img
                alt=""
                class="descargar"
                src="img/licita.png"
                width="70px"
                @click="downloadFile()"
              />
              <span class="descargar" @click="downloadFile()">
                Descargar simulación
              </span>
            </div>
          </div>
        </div>
        <!-- VOLVER Y DESCARGAR  -->
      </div>
      <!-- CONTENIDO BOTONES Y EXTRAS -->
    </div>
    <!-- DESPLIEGUE RESULTADO -->

    <div v-show="status == ''" class="p-1">
      <h1 class="tt_icons">
        <img alt="" src="img/icons.png" />
        <span class="tusim"> Simula</span> aquí con nosotros
      </h1>
    </div>

    <div v-show="status == ''" class="form-sub col-lg-9">
      <div
        v-show="alerta !== ''"
        class="alert alerta_especial mt-4"
        role="alert"
      >
        <strong><i class="bi bi-exclamation-triangle"></i> {{ alerta }}</strong>
      </div>

      <form method="post" @submit.prevent="submitForm">
        <div class="row p-4">
          <div class="col-lg-6 text-left">
            <div class="col-lg-6 mb-4 tx-uf">
              UF del día : $ {{ formatPrice(ufhoy) }}
            </div>

            <!-- Bloque 1 Input -->
            <div class="row pb-3">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>
                    <span class="tx-inputs"> Valor propiedad (UF)</span></label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">UF</div>
                    </div>
                    <CurrencyInput
                      v-model="form.sale"
                      :options="{
                        locale: 'es-CL',
                        currency: 'USD',
                        currencyDisplay: 'hidden',
                        hideGroupingSeparatorOnFocus: false,
                        hideNegligibleDecimalDigitsOnFocus: false,
                        valueRange: {
                          min: 1,
                        },
                      }"
                      class="form-control"
                      style="border-radius: 7px"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <span class="tx-inputs">Tipo de propiedad</span>
                <select
                  ref="propertyTypeWebEnum"
                  v-model="form.propertyTypeWebEnum"
                  class="form-select"
                  style="border-radius: 7px"
                >
                  <option disabled hidden selected value="">Seleccione</option>
                  <option selected value="HOUSE">CASA</option>
                  <option value="APARTMENT">DEPARTAMENTO</option>
                </select>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <span class="tx-inputs">Pie </span>
                  </div>
                  <div class="col-lg-6 text-right">
                    <span class="tx-inputs"
                      >$
                      <input
                        id="pesos"
                        v-model="picked"
                        placeholder="0"
                        style="border-radius: 7px"
                        type="radio"
                        value="pesos"
                      />
                    </span>
                    <span class="tx-inputs"
                      >UF
                      <input
                        id="uf"
                        v-model="picked"
                        placeholder="0"
                        style="border-radius: 7px"
                        type="radio"
                        value="uf"
                      />
                    </span>
                  </div>
                </div>

                <div v-if="picked === 'uf'" class="form-group">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">UF</div>
                      </div>
                      <CurrencyInput
                        v-model="form.pie"
                        :options="{
                          locale: 'es-CL',
                          currency: 'USD',
                          currencyDisplay: 'hidden',
                          hideGroupingSeparatorOnFocus: false,
                          hideNegligibleDecimalDigitsOnFocus: false,
                          autoDecimalDigits: false,
                          valueRange: {
                            min: 1,
                          },
                        }"
                        class="form-control"
                        style="border-radius: 7px"
                      />
                    </div>
                    <div class="col-lg-12">
                      <p
                        v-show="parseInt(form.pie) >= parseInt(form.sale)"
                        class="alert-max"
                      >
                        El pie es mayor al valor de la casa
                      </p>
                    </div>
                  </div>
                </div>

                <div v-if="picked === 'pesos'" class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">$</div>
                    </div>
                    <CurrencyInput
                      v-model="form.pie"
                      :options="{
                        locale: 'es-CL',
                        currency: 'CLP',
                        currencyDisplay: 'hidden',
                        precision: 0,
                        hideGroupingSeparatorOnFocus: false,
                        hideNegligibleDecimalDigitsOnFocus: true,
                        valueRange: {
                          min: 1,
                        },
                      }"
                      class="form-control"
                      style="border-radius: 7px"
                    />
                  </div>
                  <div class="col-lg-12">
                    <p v-show="form.pie / ufhoy >= form.sale" class="alert-max">
                      El pie es mayor al valor de la casa
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <span class="tx-inputs">Plazo</span>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="bi bi-calendar"></i>
                      </div>
                    </div>
                    <input
                      ref="longTerm"
                      v-model="form.longTerm"
                      class="form-control"
                      maxlength="2"
                      min="1"
                      name="logTerm"
                      oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      style="border-radius: 7px"
                      type="number"
                      @change="validateLongTerm()"
                    />
                  </div>
                  <p
                    v-show="parseInt(form.longTerm) > 30"
                    class="alert-max p-0"
                    style="color: #e461be"
                  >
                    * Plazo maximo de 30 años.
                  </p>
                  <p
                    v-show="parseInt(form.longTerm) < 8"
                    class="alert-max p-0 m-0"
                    style="color: #e461be"
                  >
                    * Plazo minimo es de 8 años.
                  </p>
                </div>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-lg-6">
                <span class="tx-inputs">Monto crédito</span>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">UF</div>
                    </div>
                    <input
                      v-if="picked === 'uf'"
                      :placeholder="getPorc(form.sale - form.pie)"
                      class="form-control"
                      disabled
                      style="border-radius: 7px"
                      type="text"
                    />
                    <input
                      v-if="picked === 'pesos'"
                      :placeholder="getPorc(form.sale - form.pie / ufhoy)"
                      class="form-control"
                      disabled
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <!-- Bloque 1 Input -->

              <!-- Bloque 2 Input -->
              <div class="col-lg-6">
                <span class="tx-inputs"> Financiamiento</span>
                <div class="form-group">
                  <div v-if="picked === 'uf'" class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                    <input
                      ref="financiamientos"
                      :v-model="financiamientos"
                      :value="
                        getPorc((form.sale - form.pie) * (100 / form.sale))
                      "
                      class="form-control"
                      disabled
                      style="border-radius: 7px"
                      type="text"
                    />
                    <div class="col-lg-12">
                      <p
                        v-show="
                          parseInt((form.sale - form.pie) * (100 / form.sale)) >
                          81
                        "
                        class="alert-max"
                      >
                        * Financiamiento Maximo es 80%
                      </p>
                    </div>
                  </div>

                  <div v-if="picked === 'pesos'" class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                    <input
                      ref="financiamientos"
                      :v-model="financiamientos"
                      :value="
                        getPorc(
                          ((form.sale - form.pie / ufhoy) * 100) / form.sale
                        )
                      "
                      class="form-control"
                      disabled
                      type="text"
                    />

                    <div class="col-lg-12">
                      <p
                        v-show="
                          parseInt(
                            ((form.sale - form.pie / ufhoy) * 100) / form.sale
                          ) > 80
                        "
                        class="alert-max"
                      >
                        * Financiamiento Maximo es 80%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-0">
              <div class="col-lg-6">
                <p class="mb-0" style="font-size: 26px; color: #17418a">
                  ¿Cuando quieres comprar?
                </p>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-lg-6">
                <span class="tx-inputs"></span>
                <select
                  ref="purchaseTime"
                  v-model="form.purchaseTime"
                  class="form-select"
                  style="border-radius: 7px"
                >
                  <option disabled hidden selected value="">Seleccione</option>
                  <option selected value="Ahora">Ahora</option>
                  <option selected value="+ de 3 meses">+ de 3 meses</option>
                  <option value="+ de 1 año">+ de 1 año</option>
                </select>
              </div>
              <div class="col-lg-6">
                <span class="tx-inputs"> RUT</span>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      ref="rut"
                      v-model="form.rut"
                      class="form-control"
                      style="border-radius: 7px"
                      type="text"
                      :maxlength="12"
                      :minlength="11"
                      @keyup="onChangeRut(form.rut)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-lg-6">
                <span class="tx-inputs"> Nombre</span>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      ref="name"
                      v-model="form.name"
                      class="form-control"
                      style="border-radius: 7px"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <span class="tx-inputs"> Correo</span>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      ref="mail"
                      v-model="form.mail"
                      class="form-control mail_ajuste"
                      style="border-radius: 7px"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Bloque BTN-->
            <div class="row mt-2">
              <div class="col-lg-5"></div>
              <div class="col-lg-12 pb-3">
                <button
                  class="btn btn-subdi w-100 bold fw-bold"
                  style="background-color: #eb39ad; border-radius: 7px"
                >
                  Simular
                </button>
              </div>
              <div class="col-lg-4"></div>
            </div>
            <!-- Bloque BTN-->
          </div>
          <div class="col-lg-6">
            <img class="img-fluid" src="img/icon_simulator.png" />
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- FORMULARIO SUBSIDIO  -->
</template>

<script>
import CurrencyInput from '../components/inputs/InputRenta.vue';
import SimulationService from '../service/rest/Simulation.service';
import UFService from '../service/rest/UF.service';
import CommonService from '../service/CommonService';
import SweetalertService from '../service/SweetalertService';

export default {
  components: {
    CurrencyInput,
  },

  data() {
    return {
      pesos: [],
      ufhoy: [],

      form: {
        sale: 0,
        credit: 0,
        subsidy: 0,
        longTerm: 8,
        mail: '',
        name: '',
        rut: '',
        origin: 'web',
        propertyTypeWebEnum: '',
        purchaseTime: '',
      },
      resultado: [],
      status: [],
      error: [],
      financiamientos: 0,
      picked: 'uf',
      simulaciones: [],
      finan: [],
      men_error: '',
      alerta: '',
      uf: [],
      rates: [],
      gastosoperacionales: [],
      clienteEmail: '',
      simulationId: '',
      decodedString: [],
      errormails: '',
      credito: 0,
      pie: 0,
      valTime: false,
      valFinan: false,
      restatus: '',
      minLongTerm: 8,
      maxLongTerm: 30,
    };
  },
  created() {
    this.obtenerUF();
  },

  methods: {
    validateLongTerm() {
      let valid = true;
      if (this.form.longTerm < this.minLongTerm) {
        this.form.longTerm = this.minLongTerm;
        valid = false;
      }
      if (this.form.longTerm > this.maxLongTerm) {
        this.form.longTerm = this.maxLongTerm;
        valid = false;
      }

      if (valid) {
        this.alerta = '';
      }
    },
    // quitamos el NaN
    getNum(val) {
      if (isNaN(val)) {
        return 0;
      }
      return val;
    },

    // quitamos el NaN y le damos formato de porcentaje
    getPorc(value) {
      if (isNaN(value)) {
        return 0;
      }
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    // creamos el formato para CLP
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    // creamos el formato para % con dos decimales
    formatporcentaje(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    validateRut() {},

    // generar la consulta POST a simulador api
    async submitForm() {
      SweetalertService.getSweetLoading('Estamos generando su simulación');
      if (!CommonService.validateEmail(this.form.mail)) {
        SweetalertService.getSweetAlertTop('Correo inválido', 'error');
        return;
      }
      if (this.form.name === '') {
        SweetalertService.getSweetAlertTop('Nombre requerido', 'error');
        return;
      }
      if (this.form.rut === '') {
        SweetalertService.getSweetAlertTop('Rut requerido', 'error');
        return;
      }
      //validacion rut valido
      let rut = this.form.rut;
      let dv = rut.substring(rut.length - 1, rut.length);
      rut = rut.slice(0, this.form.rut.length - 1);
      rut = CommonService.getUnFormattedNumber(rut);
      rut = String(rut);
      const auxDv = CommonService.checkRut(rut);
      if (dv !== auxDv) {
        SweetalertService.getSweetAlertTop('Rut invalido', 'error');
        return;
      }
      //
      if (this.form.purchaseTime === '') {
        SweetalertService.getSweetAlertTop(
          'Cuando quiere comprar requerido',
          'error'
        );
        return;
      }
      if (this.form.propertyTypeWebEnum === '') {
        SweetalertService.getSweetAlertTop(
          'Tipo de propiedad requerido',
          'error'
        );
        return;
      }
      // si eligen ingresar datos con UF validamos lo siguiente:
      if (this.picked === 'uf') {
        // guardamos los valores directamente en uf
        this.credito = this.form.sale - this.form.pie;
        this.finan = (this.credito * 100) / this.form.sale;
        //Validamos el tiempo
        this.valTime =
          parseInt(this.form.longTerm) >= 8 &&
          parseInt(this.form.longTerm) <= 30;
        //Validamos el 80%
        this.valFinan = this.finan <= 80;
      }

      // Si eligen ingresar datos con pesos dividimos el valor por la uf del dia y lo convertimos en ufs:
      if (this.picked === 'pesos') {
        this.credito = this.form.sale - this.form.pie / this.ufhoy;
        this.finan = (this.credito * 100) / this.form.sale;
        //Validamos el tiempo
        this.valTime =
          parseInt(this.form.longTerm) >= 8 &&
          parseInt(this.form.longTerm) <= 30;

        //Validamos el 80%
        this.valFinan = this.finan <= 80;
      }

      const postdata = {
        credit: Number(this.credito),
        longTerm: Number(this.form.longTerm),
        mail: this.form.mail,
        origin: this.form.origin,
        name: this.form.name,
        rut: CommonService.cleanRut(this.form.rut),
        propertyTypeWebEnum: this.form.propertyTypeWebEnum,
        purchaseTime: this.form.purchaseTime,
        sale: Number(this.form.sale),
        subsidy: 0,
      };
      //hacemos una validacion general que cumpla con tiempo y financiamiento
      if (this.valTime === true && this.valFinan === true) {
        const { data, status } = await SimulationService.simulateWeb(postdata);
        const {
          simulation,
          rateLoanTermList,
          associatedOperationalExpensesList,
        } = data;

        if (status === 200) {
          this.resultado = data;
          this.simulationId = simulation.id;
          this.clienteEmail = simulation.clientEmail;
          this.simulaciones = rateLoanTermList.sort((a, b) => {
            return a.longTermMax - b.longTermMax;
          });
          const [firstRateLoanTerm] = rateLoanTermList;
          this.rates = firstRateLoanTerm.rate;
          this.gastosoperacionales = associatedOperationalExpensesList;
          this.status = status;
          SweetalertService.getSweetAlertTop(
            'Se genero correctamente',
            'success'
          );
        } else {
          SweetalertService.getSweetAlertTop(
            'Error al generar la simulación',
            'error'
          );
        }
      } else {
        // guardamos un alert y le damos un texto alerta.
        SweetalertService.getSweetAlertTop(
          'Error al generar la simulación',
          'error'
        );
        this.alerta =
          'Hay un error en procesar tu solicitud. Recuerda que el monto maximo a financiar es de 80% y el minimo de años a cotizar son 8 años y el máximo 30 años.';
      }
    },

    // Obtener el valor de la uf del DIA
    async obtenerUF() {
      const response = await UFService.getUFDay();
      this.ufhoy = response.data.value;
    },

    // Enviamos el correo al cliente si hace click en boton
    async enviarSimulacion() {
      if (!CommonService.validateEmail(this.form.mail)) {
        SweetalertService.getSweetAlertTop('Correo inválido', 'error');
        return;
      }
      const dataSend = {
        simulationId: this.simulationId,
        mail: this.form.mail,
        name: '',
        rut: '',
        cellPhone: 0,
      };

      SweetalertService.getSweetLoading('Envíando Simulacion');
      const response = await SimulationService.sendSimulation(dataSend);

      if (response.status === 200) {
        SweetalertService.getSweetAlertTop('Se envío correctamente', 'success');
      } else {
        SweetalertService.getSweetAlertTop(
          'Error al envíar simulación',
          'error'
        );
      }
    },

    // Obtener simulacion del back y exportar archivo en front
    async downloadFile() {
      SweetalertService.getSweetLoading(
        'Generando Simulacion',
        'Esto tardará unos minutos'
      );
      const response = await SimulationService.printSimulation(
        this.simulationId
      );
      if (response.status === 200) {
        SweetalertService.getSweetAlertTop(
          'Se descargo correctamente',
          'success'
        );
      } else {
        SweetalertService.getSweetAlertTop(
          'Error al descargar la simulación',
          'error'
        );
      }
      CommonService.downloadFile(
        response.data.value,
        'Simulation.pdf',
        'application/pdf'
      );
    },
    async contact() {
      console.log(this.form.mail);
      if (!CommonService.validateEmail(this.form.mail)) {
        SweetalertService.getSweetAlertTop('Correo inválido', 'error');
        return;
      }

      const contactdata = {
        email: this.form.mail,
        creditType: 1, // subsidio
      };

      SweetalertService.getSweetLoading(
        'Envíando información',
        'Se esta obteniendo la información'
      );
      const response = await SimulationService.contact(contactdata);
      if (response.status === 200) {
        SweetalertService.getSweetAlertTop(
          'Se envío correctamente la información',
          'success'
        );
      } else {
        SweetalertService.getSweetAlertTop(
          'Error al envíar la información',
          'error'
        );
      }
    },
    onChangeRut(value) {
      if (value) {
        this.form.rut = CommonService.formatRut(this.form.rut);
      }
    },

    reset() {
      this.resultado = [];
      this.simulationId = '';
      this.simulaciones = [];
      this.rates = [];
      this.gastosoperacionales = [];
      this.status = [];
    },
  },
};
</script>
